
<template>
  <div class="error-404">
    <div class="error-title">
        <div>404</div>
        <p>很抱歉，您访问的页面不存在</p>
    </div>
    <p class="error-link">
        {{num}}秒后返回{{token?'首页':'登录页'}}
        <span class="ml-10 bb-e" @click="toHome">立即返回</span>
    </p>
  </div>
</template>
<script>
import { getStorage } from '@/utils/common'
import { validaMobile } from '@/utils/valida'
export default {
    name: "Error404",
    data() {
        return {
            countdownTime: null,
            num: 8,
            token: ''
        };
    },
    created() {
        this.token = getStorage('token')
        this.countdown();
    },
    destroyed() {
        clearTimeout(this.countdownTime)
    },
    methods: {
        // 倒计时
        countdown() {
            this.countdownTime = setInterval(() => {
                this.num--;
                if(this.num<=0){
                    this.toHome()
                }
            }, 1000);
        },
        toHome() {
            clearTimeout(this.countdownTime)
            if(this.token) {
                const query =  {
                    token: this.token
                }
                if(validaMobile()) {
                    this.$router.replace({
                        path: '/mobileHome',
                        query
                    })
                } else {
                    this.$router.replace({
                        path: '/home',
                        query
                    })
                }
            } else {
                this.$router.replace({ path: "/"});
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.error-404 {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
    .error-title{
        div{
            @include font(120px,$main_font_color,center);
            font-weight: bold;
        }
        p {
            @include font(18px,#697085,center);
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
    .error-link {
        @include font(16px,$main_font_color,center);
    }
    .ml-10 {
        margin-left: 10px;
    }
    .bb-e {
        @include font(16px,$main_color);
        cursor: pointer;
    }
}
</style>